import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import { SVG, TSVG } from '@lam-agency/toolkit/components';
import {
  getWhiteIfBackgroundIsTransparent,
  isDownloadUrl,
  isExternalUrl
} from '@lam-agency/toolkit/utils';
import * as styles from './styles.module.scss';

type TButtonVariant = 'primary' | 'secondary' | 'text';
type TButtonType = 'button' | 'reset' | 'submit';
export interface IButton {
  children: React.ReactNode;
  variant?: TButtonVariant;
  type?: TButtonType;
  display?: boolean;
  to?: string;
  iconLeft?: TSVG;
  iconRight?: TSVG;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  fluid?: boolean;
  loading?: boolean;
  active?: boolean;
  onClick?: () => void;
  className?: string;
}

const Button = ({
  children,
  variant = 'primary',
  type = 'button',
  to,
  iconLeft,
  iconRight,
  display,
  color,
  backgroundColor,
  disabled,
  fluid,
  loading,
  active,
  onClick,
  className
}: IButton) => {
  const isDownload = isDownloadUrl(to || '');
  const isExternal = isExternalUrl(to || '');

  let BtnElement: any;

  if (display) {
    BtnElement = 'div';
  } else if (isDownload) {
    BtnElement = 'a';
  } else if (to && !isExternal) {
    BtnElement = Link;
  } else if (to && isExternal) {
    BtnElement = `a`;
  } else {
    BtnElement = `button`;
  }

  const getFileName = () => {
    if (!to) return '';

    return to.split('/').pop();
  };

  const hasTextChildren = typeof children === 'string';

  return (
    <BtnElement
      type={to ? '' : type}
      download={isDownload ? getFileName() : undefined}
      href={to && (isExternal || isDownload) ? to : ''}
      target={to?.includes('?download=') ? '_self' : '_blank'}
      rel="noreferrer noopener"
      disabled={disabled}
      onClick={() => onClick && onClick()}
      tabIndex={disabled ? '-1' : 0}
      to={to && !isExternal ? to : ''}
      style={{
        color,
        background: backgroundColor || getWhiteIfBackgroundIsTransparent()
      }}
      className={cn(styles.button, className, styles[variant], {
        [styles.fluid]: fluid,
        [styles.loading]: loading,
        [styles.active]: active,
        [styles.disabled]: disabled,
        [styles.display]: display
      })}
    >
      {['primary'].includes(variant) && !display && (
        <div aria-hidden className={styles.hoverBackground} />
      )}

      <div className={styles.content}>
        {iconLeft && (
          <figure className={styles.iconContainer}>
            <SVG svg={iconLeft} className={cn(styles.icon, styles.left)} />
          </figure>
        )}

        {hasTextChildren && (
          <div
            className={cn('button', styles.textContent)}
            style={{
              textShadow: `0 0 ${color ? color : `var(--color-highlight)`}`
            }}
          >
            <span className={styles.defaultText}>{children}</span>

            {['primary'].includes(variant) && (
              <span aria-hidden className={styles.hoverText}>
                {children}
              </span>
            )}
          </div>
        )}

        {/* Probably an SVG */}
        {!hasTextChildren && children}

        {iconRight && (
          <figure className={styles.iconContainer}>
            <SVG svg={iconRight} className={cn(styles.icon, styles.right)} />
          </figure>
        )}
      </div>

      {!display && (
        <div className={styles.loaderContainer}>
          <div className={styles.loader} />
        </div>
      )}
    </BtnElement>
  );
};

const CustomButtonResolver = (props: IButton) => {
  const [Component, setComponent] = useState(() => Button);

  const loadCustomButton = async () => {
    const customComponents = (await import(
      '@lam-agency/toolkit/custom/components'
    )) as any; // to suppress error if custom components file does not exist

    const CustomComponent = customComponents.Button;

    setComponent(() => CustomComponent || Button);
  };

  useEffect(() => {
    loadCustomButton();
  }, []);

  return <Component {...props} />;
};

export default CustomButtonResolver;
